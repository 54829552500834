import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet";
import {StaticImage} from "gatsby-plugin-image";
import "../scss/index.scss";
import reactStringReplace from 'react-string-replace';

// markup
const IndexPage = () => {
  const title = '[domain] is For Sale | Identity Digital';
  const description = 'The domain name [domain] is for sale. Attract traffic with high-value keywords, coveted terms, and short domain names. Make an offer on credit.cards or contact truename.domains to learn more about how to register valuable available domain names.';

  const [returnUrl, setReturnUrl] = useState('');
  const [platinumName, setPlatinumName] = useState('[domain]');
  const [thankYou, setThankYou] = useState(false);
  const [domain, setDomain] = useState('');
  const [actionUrl, setActionUrl] = useState('');

  useEffect(() => {
    const url = new URL(window.location.href);

    setPlatinumName(url.hostname);
    setThankYou(url.searchParams.has('thankyou'));

    url.searchParams.set('thankyou', 'yes');

    // url.searchParams.set('Platinum_Lead_Source', 'Inbound - Organic');
    // url.searchParams.set('Platinum_Lead_Source_Details_LP', 'Premium.Domains Landing Page');

    setReturnUrl(url.toString());
    setActionUrl('https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&oid=00DD0000000CWb4');

    const domain = window.location.hostname.split('.').reverse().slice(0, 2).reverse().join('.');
    setDomain(domain);

    document.title = `${domain} is For Sale | Identity Digital`;
  }, []);

  return <>
    <Helmet
      htmlAttributes={{lang: 'en'}}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]}
    />

    <Helmet
      script={[
        {src: 'https://www.googletagmanager.com/gtag/js?id=G-X3YME8FVBE', async: true},
        {innerHTML: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-X3YME8FVBE');`},
      ]}
    />

    <header className="container">
      <StaticImage src="../images/logo.png" alt="Logo" width={300} breakpoints={[200, 300]} layout="constrained" placeholder="none" />
      <div>
        <span>CONTACT US: <a href="tel:+1-425-298-1633">+1-425-298-1633</a></span>
        <span className="sm">|</span>
        <a href="mailto:invest@identity.digital">invest@identity.digital</a>
      </div>
    </header>

    <main>
      <div className="hero">
        <div className="container">
          <div className="inner">
            <h1>The domain name <strong>{reactStringReplace('[domain]', '[domain]', () => (<>{domain}</>))}</strong> is for sale.</h1>

            {/*{pardotUrl && <iframe title="Contact Form" src={pardotUrl} width="100%" height="300" frameBorder="0" allowTransparency="true" />}*/}


            {!thankYou && <form action={actionUrl} method="POST">

              <input type="hidden" name="oid" value="00DD0000000CWb4" />
              <input type="hidden" name="00N4G000006zlze" value="Inbound - Organic" />
              <input type="hidden" name="00N4G000006zlzj" value="Premium.Domains Landing Page" />
              <input type="hidden" name="00N4G000006zlzy" value={platinumName} />
              <input type="hidden" name="retURL" value={returnUrl} />

              <label>
                ENTER YOUR EMAIL ADDRESS *:
                <input maxLength="80" name="email" size="20" type="email" required={true} />
              </label>

              <label>
                <input type="checkbox" required={true} />
                I agree that Identity.Digital may contact me via email for the above stated purposes and I acknowledge that I have read, understand and accept the terms of the <a href="https://donuts.domains/about/policies/privacy-policy/" target="_blank" rel="noreferrer">Identity.Digital Privacy Policy</a>
              </label>

              <input type="submit" name="submit" value="SEND ME THE DETAILS" />
            </form>}

            {thankYou && <h3>Thank you! Your request is received!</h3>}
          </div>
        </div>
      </div>

      <div className="row1">
        <div className="container">
          <div className="inner">
            <div>
              <h2>What’s so great about <strong>{reactStringReplace('[domain]', '[domain]', () => (<>{domain}</>))}</strong></h2>
              <p>This domain is short, memorable, and made of valuable keywords. It will help you stand out in search results listings, convey what you do, and catch your customers’ attention.</p>
              <p>The domain rome.apartments is available now, make it yours while you still can!</p>
            </div>

            <div>
              <div className="icon">
                <StaticImage src="../images/rocket.png" alt="" width={96} layout="fullWidth" placeholder="none" />
                <div>
                  <h4>POWER YOUR BRAND</h4>
                  <p>Join the ranks of top brands and startups.</p>
                </div>
              </div>
              <div className="icon">
                <StaticImage src="../images/magnet.png" alt="" width={96} layout="fullWidth" placeholder="none" />
                <div>
                  <h4>CONNECT WITH NEW CUSTOMERS</h4>
                  <p>Keyword-rich domains can boost search rankings.</p>
                </div>
              </div>
              <div className="icon">
                <StaticImage src="../images/arrow.png" alt="" width={96} layout="fullWidth" placeholder="none" />
                <div>
                  <h4>GOING, GOING, GONE</h4>
                  <p>Don’t miss out. Great domains sell fast.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid row2">
        <div className="container">
          <div className="img">
            <StaticImage src="../images/img2.jpg" alt="" breakpoints={[300, 375, 400, 425, 428, 500]} layout="fullWidth" placeholder="none" />
          </div>
          <div className="text">
            <h2>Don’t take our word for it</h2>
            <p>“Using a new domain ending will not hurt your search presence.” – Google</p>
            <p>“… brands now have more options to create a distinctive domain name that quickly communicates the purpose and value of their offering. … short and memorable [new TLD] domain names are often still available” – Google</p>
            <p>“Relevant words help users find you in a search and recognize what you do at a glance. Keywords can include what you do or offer (like “coffee” or “cleaning”), or even your location.” – Google</p>
            <p>“Your domain name is often one of the first things someone sees, and it can affect their impression of your site and your brand.” – Google</p>
          </div>
        </div>
      </div>

      <div className="grid row3">
        <div className="container">

          <div className="row info">
            <div className="percent">94%</div>
            <div>of Fortune 50 companies own or use Identity Digital domains</div>
          </div>

          <div className="row domains">
            <div>AMAZON.<strong>CARE</strong></div>
            <div>DISNEY.<strong>PLUS</strong></div>
            <div>APPLE.<strong>WATCH</strong></div>
          </div>
        </div>
      </div>

      <div className="grid row4">
        <div className="container">
          <div className="text">
            <h2>Claim the domain <strong>{reactStringReplace('[domain]', '[domain]', () => (<>{domain}</>))}</strong> today</h2>
          </div>
          <div className="img" style={{display: 'none'}}>
            <StaticImage src="../images/img4.jpg" alt="" width={320} placeholder="none" />
          </div>
        </div>
      </div>
    </main>

    <footer>
      <div className="container center">
        <div className="row">
          <div className="logo">
            <StaticImage src="../images/logo-footer.png" alt="Logo" width={292} breakpoints={[200, 292]} layout="constrained" placeholder="none" />
          </div>
          <ul className="menu">
            <li><a href="https://identity.digital/products-services/security/" rel="external noopener noreferrer">Security</a></li>
            <li><a href="https://identity.digital/article-categories/faq/" rel="external noopener noreferrer">FAQs</a></li>
            <li><a href="https://identity.digital/managed-dns/" rel="external noopener noreferrer">Managed DNS</a></li>
            <li><a href="https://identity.digital/identity-digital-solutions/" rel="external noopener noreferrer">Contact</a></li>
            <li><a href="https://identity.digital/news/" rel="external noopener noreferrer">News</a></li>
            <li><a href="https://identity.digital/policies/privacy-policy/" rel="external noopener noreferrer">Privacy Policy</a></li>
            <li><a href="https://identity.digital/policies/website-terms-of-use/" rel="external noopener noreferrer">Terms</a></li>
          </ul>
        </div>

        <p>
          Copyright © 2022, Identity Digital Inc.,<br />
          10500 NE 8th Street Suite 750 Bellevue, WA 98004 All Rights Reserved.
        </p>

        <p>
          <small>
            Identity Digital, and Identity Digital logo, and other trademarks, service marks, and designs are registered or unregistered trademarks of Identity Digital and its subsidiaries in the United States and in other countries. All other trademarks are property of their respective owners.
          </small>
        </p>
      </div>
    </footer>

  </>
};

export default IndexPage;
